import React from "react"
import { graphql } from "gatsby"
import { Disqus } from 'gatsby-plugin-disqus';
import TimeAgo from 'react-timeago';
import Layout from '../components/layout'

export default function BlogPost({ location, data }) {
  const post = data.markdownRemark
  const title = "Blog | " + post.frontmatter.title;
  const disqusConfig = {
    url: data.site.siteMetadata.siteUrl + location.pathname,
    identifier: post.id,
    title: post.title,
  }

  return (
    <Layout pageTitle={title}>
      <div className="container">
        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">{post.frontmatter.title}</h1>
          <small>Written by {post.frontmatter.author}, <TimeAgo date={post.frontmatter.date} /></small>
        </div>
        <div className="mb-5">
          <div className="container">
            <div className="row">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>
        <Disqus config={disqusConfig} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        author
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
